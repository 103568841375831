import React from "react";
import './Prog.css';
import {programsData} from '../data/programsData'
import Rightarrow from '../assets/rightArrow.png'

const Prog=()=>{
    return(
        <div className="prog" id="prog">
             <div className="prog-header">
                <span className="stroke-text">Explore our</span>
                <span>Programm</span>
                <span className="stroke-text">To shape you</span>
             </div>
             <div className="prog-cat">
                {programsData.map((program)=>(
                    <div className="cat">
                        {program.image}
                        <span>{program.heading}</span>
                        <span>{program.details}</span>
                        <div className="join-now">
                            <span>join now</span>
                            <img src={Rightarrow} alt=""/>
                        </div>
                    </div>
                    
                ))}
             </div>




        </div>
    )
}

export default Prog;