import './App.css';
import Hero from './com/Hero';
import Prog from './programm/Prog';
import Reasone from './Reasone/Reasone';
import Plan from './plan/Plan';
import Testi from './Testi/Testi';
import Join from './join/Join';
function App() {
  return (
    <div className='App'>
      <Hero></Hero>
      <Prog></Prog>
      <Reasone></Reasone>
      <Plan></Plan>
      <Testi></Testi>
      <Join></Join>
    </div>
          

  
  );
}

export default App;
