import React, { useRef } from "react";
import "./Join.css"

const Join=()=>{
    const form=useRef()
    return(
        <div className="join" id="join-us">
        <div className="left-j">

        </div>
        <div className="right-j">
            <form ref={form} action="" className="email-con">
                <input type="email" name="user-email" placeholder="enter email"></input>
                <button className="btn btn-j">join us </button>
            </form>
        </div>
        </div>
    )
}
export default Join