import React from "react";
import "./Plan.css"
import whitetick from "../assets/whiteTick.png"
import {plansData} from "../data/plansData.js"
const Plan=()=>{
    return(
        <div className="plan-container">
            <div className="prog-header" style={{gap:'2rem'}}>
                <span className="stroke-text">READY TO START</span>
                <span>YOUR JOURNEY</span>
                <span className="stroke-text">NOW WITH US</span>
            </div>
            <div className="plans">
                {
                    plansData.map((plan,i)=>(
                        <div className="plan" key={i} >
                            {plan.icon}
                            <span>{plan.name}</span>
                            <span>${plan.price}</span>
                            <div className="features">
                                {
                                    plan.features.map((feature,i)=>(
                                        <div className="feature">
                                            <img src={whitetick} alt=""></img>
                                            <span key={i} >{feature}</span>
                                        </div>

                                    ))
                                }
                                <div>
                                    <span > see more benifite -> </span>
                                </div>
                                <button className="btn">join now</button>
                            </div>
                        </div>
                    ))
                }

            </div>

        </div>
    )

}
export default Plan;