import React, { useState } from "react";
import "./Testi.css"
import {testimonialsData} from "../data/testimonialsData"
import leftarrow from "../assets/leftArrow.png"
import rightarrow from "../assets/rightArrow.png"
const Testi=()=>{
    const [selected,setselected]=useState(0)
    const tlenght=testimonialsData.length;


    return(
        <div className="testi">
            <div className="left-t">
                <span className="Testimonials"></span>
                <span className="stroke-text"> what they</span>
                <span >say about us</span>
                <span>
                    {testimonialsData[selected].review}
                </span>
                <span>
                    <span style={{color:'var(--orange)'}}>{testimonialsData[selected].name}</span>{" "}
                    -{testimonialsData[selected].status}
                </span>
            </div>
            <div className="right-t">
                <div></div>
                <div></div>
                <img src={testimonialsData[selected].image} alt=""></img>
            </div>
            <div className="arrows">
                <img onClick={()=>{
                    selected===0
                    ?setselected(tlenght-1)
                    :setselected((prev)=>prev-1);
                }} src={leftarrow} alt="" />

                <img onClick={()=>{
                    selected===tlenght-1
                    ?setselected(0)
                    :setselected((prev)=>prev+1);
                }}
                src={rightarrow} alt=""/>
            </div>





        </div>
        
    )
}

export default Testi