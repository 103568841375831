import React from "react";
import './Reasone.css'
import image1 from "../assets/image1.png";
import image2 from "../assets/image2.png";
import image3 from "../assets/image3.png";
import image4 from "../assets/image4.png";
import nb from "../assets/nb.png";
import adidas from "../assets/adidas.png";
import nike from "../assets/nike.png";
import tick from "../assets/tick.png";




const Reasone=()=>{
    return(
        <div className="Reasone" id="reasone">
            <div className="left-r">
                <img src={image1} alt=""/>
                <img src={image2} alt=""/>
                <img src={image3} alt=""/>
                <img src={image4} alt=""/>
            </div>

            <div className="right-r">
                <span> some reasone </span>
                <div>
                    <span className="stroke-text">why</span>
                    <span>choose us?</span>
                </div>
                <div className="details-r">
                    <div><img src={tick} alt=""></img> <span>over 140+ excersie</span></div>
                    <div><img src={tick} alt=""></img> <span>train smarter and faster than before</span></div>
                    <div><img src={tick} alt=""></img> <span></span>1 free program for new member</div>
                    <div><img src={tick} alt=""></img> <span></span>reliable partner</div>
                </div>
                
                <span className="hh">our partner</span>
                <div className="partners">
                    <img src={nb} alt=""></img>
                    <img src={adidas} alt=""></img>
                    <img src={nike} alt=""></img>
                </div>




            </div>

        </div>
    )
}
export default Reasone