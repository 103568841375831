import React from "react";
import './Header.css';
import Log from '../assets/logo.png';

const Header = ()=>{
    return(
        <div className="header">
            <img src= {Log} alt="nanan" className="logo"/>
            <ul className="header-menu">
                <li>Home</li>
                <li>Programmes</li>
                <li>Why us</li>
                <li>Plans</li>
                <li>Testimonials</li>
            </ul>
            
        </div> 

    )
}
export default Header