import React from "react";
import Header from "../header/Header";
import './Hero.css';
import hero_image from '../assets/hero_image.png';
import hero_image_back from '../assets/hero_image_back.png';
import heart from '../assets/heart.png';
import calories  from '../assets/calories.png';
const Hero= ()=>{
 return(
     <div className="hero">
        <div className='left-h'>
            <Header/>
            <div className="the-best-ad">
                <div></div>
                <span>the best fitness clube in the town</span>
            </div>


            <div className="hero-text">
                <div>
                    <span className="stroke-text">Shape </span>
                    <span>your</span>
                </div>
                <div>
                    <span>ideal body</span>
                </div>
                <div >
                    <span> In here we all help you to build your idea body</span>
                </div>

                <div className="figures">
                    <div>
                        <span>140+</span>
                        <span>expert coused</span>
                    </div>
                    <div>
                        <span>959</span>
                        <span>joined member</span>
                    </div>
                    <div>
                        <span>+50</span>
                        <span>fitnes programma</span>
                    </div>

                </div>


            </div>
            <div className="hero-button">
                <button className="btn">Get started</button>
                <button className="btn">Learn more</button>
            </div>

            
        </div>   
        <div className='right-h'>
            <button className="btn">join now</button>
            <div className="heart-rate">
                <img src={heart} alt=""/>
                <span>Heart rate</span><span>114 bpn</span>
            </div>
            <img src={hero_image} alt="" className="hero-img"/>
            <img src={hero_image_back} alt="" className="hero-img-back"/>
            <div className="calories">
                <img src={calories} alt="" className=""/>
                <div>
                <span>Calories burned </span> <span>123 krel</span>
                </div>
            </div>
            
        </div>     




    </div>
 )
    

}

export default Hero;